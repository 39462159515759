/** @jsx jsx */
// eslint-disable-next-line
import React from "react"
import { jsx } from "theme-ui"
import { Box, Flex } from "rebass"

export default ({ ...props }) => (
  <Flex
    sx={{
      flexWrap: "wrap",
      textAlign: "center",
      //fontSize: 1,
    }}
  >
    <Box
      width={9 / 9}
      sx={{
        p: 3,
        fontSize: 2,
      }}
    >
      Good under supervision with children
    </Box>
    <Box
      width={9 / 9}
      sx={{
        p: 3,
        fontSize: 2,
      }}
    >
      Good under supervision with other dogs
    </Box>

    {/* 
    <Box
      width={3 / 3}
      sx={{
        p: 1,
        fontSize: 0,
      }}
    >
      Tap or hover anywhere to learn more.
    </Box> */}
  </Flex>
)
