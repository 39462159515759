/** @jsx jsx */
// eslint-disable-next-line
import React from "react"
import { jsx } from "theme-ui"
import { Box, Flex } from "rebass"
import Primary from "./cardPrimary"
import CTA from "./cardCTA"
import Activity from "./cardActivity"
import Insights from "./cardInsights"
import ExtraStuff from "./cardExtra"
import { Tab, Tabs, TabList, TabPanel } from "react-tabs"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Tooltip } from "react-tippy"
import "react-tippy/dist/tippy.css"
import { size } from "styled-system"

const CustomTab = ({ children }) => (
  <Tab
    sx={{
      p: 0,
      m: 0,
      width: "100%",
    }}
  >
    <h4
      sx={{
        textTransform: "uppercase",
        display: "inline-block",
        textAlign: "center",
        width: "100%",
        m: 0,
        p: 3,
        bg: "primay004",
        borderWidth: 0,
        color: "primaryDark",
        "&:hover": {
          bg: "primary016",
          boxShadow: " 0 2px 8px -4px  rgba(0,0,0,0.32)",
        },
      }}
    >
      {children}
    </h4>
  </Tab>
)

CustomTab.tabsRole = "Tab" // Required field to use your custom Tab

export default ({ image, title, description }) => (
  <Box
    sx={{
      p: 0,
      bg: "background",
      borderBottomLeftRadius: 4,
      borderBottomRightRadius: 4,
    }}
  >
    <Tabs>
      <TabList
        sx={{
          listStyle: "none",
          p: 0,
          m: 0,
        }}
      >
        <Flex
          sx={{
            flexWrap: "nowrap",
            flexDirection: "row",
          }}
        >
          <CustomTab>
            <Tooltip
              title="Primary"
              position="top"
              trigger="mouseenter focus"
              interactive="true"
              touchHold="true"
              popperOptions={{
                size: "small",
              }}
            >
              <FontAwesomeIcon
                sx={{
                  color: "primaryDark",
                }}
                icon={["fas", "dog"]}
              />
            </Tooltip>
          </CustomTab>
          <CustomTab>
            <Tooltip
              title="Insights"
              position="top"
              trigger="mouseenter focus"
              interactive="true"
              touchHold="true"
              popperOptions={{
                size: "small",
              }}
            >
              <FontAwesomeIcon
                sx={{
                  color: "primaryDark",
                }}
                icon={["fas", "lightbulb-on"]}
              />
            </Tooltip>
          </CustomTab>
          <CustomTab>
            <Tooltip
              title="Activity"
              position="top"
              trigger="mouseenter focus"
              interactive="true"
              touchHold="true"
              popperOptions={{
                size: "small",
              }}
            >
              <FontAwesomeIcon
                sx={{
                  color: "primaryDark",
                }}
                icon={["fas", "heart-rate"]}
              />
            </Tooltip>
          </CustomTab>
          <CustomTab>
            <Tooltip
              title="Other"
              position="top"
              trigger="mouseenter focus"
              interactive="true"
              touchHold="true"
              popperOptions={{
                size: "small",
              }}
            >
              <FontAwesomeIcon
                sx={{
                  color: "primaryDark",
                  width: "100%",
                }}
                icon={["fas", "bone"]}
              />
            </Tooltip>
          </CustomTab>
        </Flex>
      </TabList>
      <TabPanel>
        <Primary />
      </TabPanel>
      <TabPanel>
        <Insights />
      </TabPanel>
      <TabPanel>
        <Activity />
      </TabPanel>
      <TabPanel>
        <ExtraStuff />
      </TabPanel>
    </Tabs>
    <CTA />
  </Box>
)
