/** @jsx jsx */
// eslint-disable-next-line
import React from "react"
import { jsx } from "theme-ui"
import { Box, Flex } from "rebass"

export default ({ ...props }) => (
  <Flex
    sx={{
      flexWrap: "wrap",
      textAlign: "center",
      //fontSize: 1,
    }}
  >
    <Box
      width={4 / 9}
      sx={{
        p: 3,
        fontSize: 2,
      }}
    >
      Intelligent
    </Box>
    <Box
      width={2 / 9}
      sx={{
        p: 3,
        fontSize: 2,
      }}
    >
      Lively
    </Box>
    <Box
      width={3 / 9}
      sx={{
        p: 3,
        fontSize: 2,
      }}
    >
      Athletic
    </Box>
    <Box
      width={3 / 9}
      sx={{
        p: 3,
        fontSize: 2,
      }}
    >
      Agreeable
    </Box>
    <Box
      width={2 / 9}
      sx={{
        p: 3,
        fontSize: 2,
      }}
    >
      Alert
    </Box>
    <Box
      width={4 / 9}
      sx={{
        p: 3,
        fontSize: 2,
      }}
    >
      Independent
    </Box>

    {/* 
    <Box
      width={3 / 3}
      sx={{
        p: 1,
        fontSize: 0,
      }}
    >
      Tap or hover anywhere to learn more.
    </Box> */}
  </Flex>
)
