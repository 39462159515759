/** @jsx jsx */
import { jsx } from "theme-ui"
import React, { Component } from "react"
import Card from "./cardNormal"
import { Flex, Box } from "rebass"
import StackGrid, { transitions } from "react-stack-grid"

const { scaleDown } = transitions

const cards = [
  1,
  2,
  3,
  4,
  5,
  6,
  7,
  8,
  9,
  10,
  11,
  12,
]

const Cards = ({ ...props }) => (
  <StackGrid
    // more...
    columnWidth={352}
    appear={scaleDown.appear}
    appeared={scaleDown.appeared}
    enter={scaleDown.enter}
    entered={scaleDown.entered}
    leaved={scaleDown.leaved}
    gutterWidth={16}
    gutterHeight={4}
  >
    {cards.map(card => (
      <Box>
        <Card />
      </Box>
    ))}
  </StackGrid>
)
export default Cards
