/** @jsx jsx */
// eslint-disable-next-line
import React from "react"
import { jsx } from "theme-ui"
import Card from "./card"
import Top from "./cardTop"
import Bottom from "./cardBottom"

export default ({ image, title, description }) => (
  <Card>
    <Top />
   <Bottom />
  </Card>
)
