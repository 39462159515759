/** @jsx jsx */
// eslint-disable-next-line
import React from "react"
import { jsx } from "theme-ui"
import { Box } from "rebass"
//import Tilt from "react-tilt"

const Card = ({ variant = "primary", ...props }) => (
  <Box
    {...props}
    width={["102vw", "352px", "352px", "352px"]}
    my={[2, 2, 2, 2]}
    mx={[0, 2, 2, 2]}
    sx={{
      p: 0,
      justifySelf: "stretch",
      borderRadius: 4,
      //maxWidth: "352px",
      boxShadow:
        "0 4px 16px -4px rgba(0,0,0,0.24), 0 1px 2px -2px  rgba(0,0,0,0.24)",
      transition: "all 0.3s cubic-bezier(.25,.8,.25,1)",
      "&:hover": {
        boxShadow:
          "0 4px 16px -4px rgba(0,0,0,0.32), 0 1px 4px -2px rgba(0,0,0,0.24)",
      },
    }}
  />
)
export default Card
