/** @jsx jsx */
// eslint-disable-next-line
import React from "react"
import { jsx } from "theme-ui"
import { StaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import styled from "styled-components"

/*
 * This component is built using `gatsby-image` to automatically serve optimized
 * images with lazy loading and reduced file sizes. The image is loaded using a
 * `StaticQuery`, which allows us to load the image from directly within this
 * component, rather than having to pass the image data down from pages.
 *
 * For more information, see the docs:
 * - `gatsby-image`: https://gatsby.app/gatsby-image
 * - `StaticQuery`: https://gatsby.app/staticquery
 */

const Image = () => (
  <StaticQuery
    query={graphql`
      query {
        placeholderImage: file(relativePath: { eq: "molly-puppy-5.jpeg" }) {
          childImageSharp {
            fluid(
              cropFocus: ATTENTION
              quality: 75
              maxWidth: 352
              maxHeight: 352
            ) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `}
    render={data => (
      <Img
        //borderTopRightRadius={[0, 8, 8, 8]}
        //borderTopLeftRadius={[0, 8, 8, 8]}
        sx={{
          borderTopRightRadius: 4,
          borderTopLeftRadius: 4,
        }}
        fluid={data.placeholderImage.childImageSharp.fluid}
      />
    )}
  />
)

const StyledImage = styled(Image)``

export default StyledImage
