/** @jsx jsx */
import { jsx } from "theme-ui"
// eslint-disable-next-line
import React from "react"
import { Flex } from "rebass"
//import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import Cards from "../components/cards"
import { Container } from "./layoutComponents"

const Featured = () => (
    <Container>
    <Flex sx={{}}>
      <Flex
        sx={{
          my: 4,
          //fontSize: 21,
          flexDirection: "column",
          //width: "900px",
          m: "auto",
          py: 4,
          //justifyContent: "flex-start",
          //alignItems: "flex-start",
        }}
      >
        <h1
          sx={{
            my: 0,
          }}
        >
          Featured Dogs
        </h1>
      </Flex>
    </Flex>

    <Flex
      sx={{
        flexDirection: "column",
        //my: 4,
      }}
    >
      <Cards sx={{}} />
    </Flex>
    </Container>
)

export default Featured
