/** @jsx jsx */
import { jsx } from "theme-ui"
// eslint-disable-next-line
import React from "react"
import Button from "./button"
import { Flex, Box } from "rebass"
//import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import Card from "./democardNormal"
import Link  from "./link"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {  Input,  } from "@rebass/forms"
import { Container } from "./layoutComponents"



const Headline = () => (
  <Container>
    <Flex
      sx={{
        flexDirection: "column",
        m: "auto",
        mt: 83.2,
        py: 4,
      }}
    >
      <Flex
        sx={{
          flexWrap: "wrap",
          m: "auto",
          justifyContent: "space-around",
        }}
      >
        <Box
          sx={{
            flex: "1 1 420px",
            m: "auto",
            maxWidth: "1072px",
          }}
        >
          <h1
            sx={{
              m: 0,
              p: 3,
            }}
          >
            A Better Way to Adopt Dogs
          </h1>
          <p
            sx={{
              m: 0,
              p: 3,
            }}
          >
            <b>Search for dogs near you below.</b> Our goals are to reduce the
            number of dogs entering shelters and see more of them find the right
            homes. <Link>Learn how signing up for a free account</Link> will
            help you find the perfect canine companion.
          </p>
          <Flex
            sx={{
              //m: 4,
              p: 2,
              //flexWrap: "wrap",
              justifyContent: "center",
            }}
          >
            <Box
              as="form"
              onSubmit={e => e.preventDefault()}
              sx={{
                m: 1,
                flex: "1 1 147px",
              }}
            >
              {/* <Label htmlFor="ZipCode">Zip Code</Label> */}
              <Input
                sx={{
                  m: 0,
                  p: 2,
                  borderRadius: 4,
                  borderWidth: 2,
                  variant: "buttons.gray",
                  "&:hover": {
                    color: "primary",
                    bg: "transparent",
                    borderStyle: "solid",
                    borderColor: "primary",
                    borderWidth: 2,
                    "&:hover": {
                      bg: "rgba(48, 53, 54, 0.05)",
                      borderColor: "transparent",
                      color: "primaryDark",
                    },
                  },
                }}
                id="location"
                name="location"
                defaultValue="Seattle, WA 98101"
              />
            </Box>

            <Button
              sx={{
                variant: "buttons.primary",
                m: 1,
                //maxWidth: "552px",
                flex: "0 0 147px",
              }}
            >
              <FontAwesomeIcon icon={["fas", "search"]} />
            </Button>
          </Flex>
          <p
            sx={{
              p: 3,
              m: 0,
            }}
          >
            Interested in using
            <span
              sx={{
                fontFamily: "KiriFont",
                letterSpacing: "-1.5px",
              }}
            >
              {" "}
              AllTails{" "}
            </span>
            as an organization?{" "}
            <Link to="./listing">
              <b>Click here!</b>
            </Link>
          </p>
        </Box>
        <Box
          //p={[0, 2, 3, 4]}
          sx={{
            //flex: "0 0 auto",
            //transform: "scale(0.88)",
            opacity: 0.9,
            //p: 3,
          }}
        >
          <Flex
            sx={{
              justifyContent: "center",
              justifySelf: "center",
              p: 4,
              m: 0,
            }}
          >
            <Card />
          </Flex>
        </Box>
      </Flex>
    </Flex>
  </Container>
)

export default Headline
