/** @jsx jsx */
// eslint-disable-next-line
import React from "react"
import { jsx } from "theme-ui"
import { Box } from "rebass"
import Button from "./button"

export default ({ image, title, description }) => (
  <Box
    sx={{
      p: 3,
    }}
  >
    <Button
      sx={{
        variant: "buttons.primary",
      }}
    >
      APPLY TO ADOPT
    </Button>
  </Box>
)
