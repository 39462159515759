/** @jsx jsx */
// eslint-disable-next-line
import React from "react"
import { jsx } from "theme-ui"
import Card from "./demoCard"
import Top from "./cardTop"
import Bottom from "./cardBottom"

export default ({ image, title, description }) => (
  <Card
    sx={
      {
        //p:3,
      }
    }
  >
    <Top />

    <Bottom />
  </Card>
)
