/** @jsx jsx */
import { jsx } from "theme-ui"
import { Flex, Box } from "rebass"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

const Button = ({ ...props }) => (
  <h3
    sx={{
      m: 0,
    }}
  >
    <button
      {...props}
      sx={{
        position: "absolute",
        p: 3,
        m: 0,
        bg: "transparent",
        borderWidth: 0,
      }}
    />
  </h3>
)

export const IconLike = ({ ...props }) => (
         <Button
           sx={{
             position: "absolute",
             p: 3,
             right: 0,
             bottom: 0,
           }}
         >
           <FontAwesomeIcon
             sx={{
               color: "overlayText",
               m: 0,
             }}
             icon={["fas", "heart"]}
           />
         </Button>
       )

export const IconExpand = ({ ...props }) => (
         <Button
           sx={{
             position: "absolute",
             p: 3,
             top: 0,
             left: 0,
           }}
         >
           <FontAwesomeIcon
             sx={{
               color: "overlayText",
               m: 0,
             }}
             icon={["fas", "expand"]}
           />
         </Button>
       )

export const IconShare = ({ ...props }) => (
         <Button
           sx={{
             position: "absolute",
             p: 3,
             top: 0,
             right: 0,
           }}
         >
           <FontAwesomeIcon
             sx={{
               color: "overlayText",
               m: 0,
             }}
             icon={["fas", "share"]}
           />
         </Button>
       )

export const DogName = ({ ...props }) => (
  <Box sx={{ mt: 1 }}>
    <h2
      sx={{
        color: "overlayText",
        m: 0,
      }}
    >
      Molly
    </h2>
  </Box>
)

export const ImageDots = ({ ...props }) => (
  <Box sx={{ mb: 1 }}>
    <Flex
      sx={{
        flexDirection: "row",
        //p: 2,
        //textShadow: "0px 1px 8px rgba(48, 53, 54, 0.64)",
      }}
    >
      <Box
        sx={{
          width: "8px",
          height: "8px",
          bg: "primary",
          borderRadius: "50%",
          m: 1,
        }}
      />
      <Box
        sx={{
          width: "8px",
          height: "8px",
          borderRadius: "50%",
          m: 1,
          bg: "rgba(242, 250, 251, 0.75)",
          "&:hover": {
            bg: "rgba(242, 250, 251, 1)",
          },
        }}
      />
      <Box
        sx={{
          width: "8px",
          height: "8px",
          borderRadius: "50%",
          m: 1,
          bg: "rgba(242, 250, 251, 0.75)",
          "&:hover": {
            bg: "rgba(242, 250, 251, 1)",
          },
        }}
      />
      <Box
        sx={{
          width: "8px",
          height: "8px",
          borderRadius: "50%",
          m: 1,
          bg: "rgba(242, 250, 251, 0.75)",
          "&:hover": {
            bg: "rgba(242, 250, 251, 1)",
          },
        }}
      />
      <Box
        sx={{
          width: "8px",
          height: "8px",
          borderRadius: "50%",
          m: 1,
          bg: "rgba(242, 250, 251, 0.75)",
          "&:hover": {
            bg: "rgba(242, 250, 251, 1)",
          },
        }}
      />
    </Flex>
  </Box>
)

export const BottomLeft = ({ ...props }) => (
  <Flex
    sx={{
      flexDirection: "column",
      position: "absolute",
      p: 3,
      bottom: 0,
      left: 0,
    }}
  >
    <ImageDots />
    <DogName />
  </Flex>
)

export default Button
