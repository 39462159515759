/** @jsx jsx */
// eslint-disable-next-line
import React from "react"
import { jsx } from "theme-ui"
import { Box, Flex } from "rebass"

export default ({ ...props }) => (
  <Flex
    sx={{
      flexWrap: "wrap",
      textAlign: "center",
      //fontSize: 1,
    }}
  >
    <Box
      width={2 / 3}
      sx={{
        p: 3,
        fontSize: 2,
      }}
    >
      Dutch Shepherd
    </Box>
    <Box
      width={1 / 3}
      sx={{
        p: 3,
        fontSize: 2,
      }}
    >
      36 lbs.
    </Box>
    <Box
      width={1 / 3}
      sx={{
        p: 3,
        fontSize: 2,
      }}
    >
      5 mos.
    </Box>
    <Box
      width={1 / 3}
      sx={{
        p: 3,
        fontSize: 2,
      }}
    >
      Female
    </Box>
    <Box
      width={1 / 3}
      sx={{
        p: 3,
        fontSize: 2,
      }}
    >
      67 days
    </Box>
  </Flex>
)
