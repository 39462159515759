/** @jsx jsx */
import { jsx, Box } from "theme-ui"
import Link from "./link"
import CountUp from "react-countup"

const Banner = ({ ...props }) => (
  <div
    {...props}
    sx={{
      position: "relative",
      //width: "100vw",
      bg: "rgba(48, 53, 54, 0.05)",
      //mx: "-50vw",
      //left: "50%",
      //right: "50%",
      my: 4,
      textAlign: "center",
      py: 3,
    }}
  />
)

export const PuppyMillBanner = ({ ...props }) => (
         <Banner>
           <Box
             sx={{
               p: 3,
             }}
           >
             <b
               sx={{
                 p: 3,
                 color: "rgba(48, 53, 54, 0.64)",
               }}
             >
               An estimated{" "}
               <CountUp end={10000} duration={2.75} separator="," /> puppy mills
               breed <CountUp end={2000000} duration={6.75} separator="," />{" "}
               puppies each year.{" "}
             </b>

             <br />
           </Box>
           <Link
             sx={{
               p: 3,
               textAlign: "center",
             }}
           >
             See what
             <span
               sx={{
                 fontFamily: "KiriFont",
                 letterSpacing: "-1.5px",
               }}
             >
               {" "}
               AllTails{" "}
             </span>
             is doing to help.
           </Link>
         </Banner>
       )

       export const PurebredBanner = ({ ...props }) => (
                <Banner>
                  <Box
                    sx={{
                      p: 3,
                    }}
                  >
                    <b
                      sx={{
                        p: 3,
                        color: "rgba(48, 53, 54, 0.64)",
                      }}
                    >
                      1 out of every 4 dogs entering shelters each are purebred.
                    </b>

                    <br />
                  </Box>
                  <Link
                    sx={{
                      p: 3,
                      textAlign: "center",
                    }}
                  >
                    See what
                    <span
                      sx={{
                        fontFamily: "KiriFont",
                        letterSpacing: "-1.5px",
                      }}
                    >
                      {" "}
                      AllTails{" "}
                    </span>
                    is doing to help.
                  </Link>
                </Banner>
              )



export default Banner
