/** @jsx jsx */
// eslint-disable-next-line
import React from "react"
import { jsx } from "theme-ui"
import { Flex, Box } from "rebass"
import Image from "./molly"
import Image3 from "./molly3"
import Image2 from "./molly2"
import Image4 from "./molly4"
import Image5 from "./molly5"
import {
  IconLike,
  IconExpand,
  IconShare,
  DogName,
} from "./cardIcons"
import { Tab, Tabs, TabList, TabPanel } from "react-tabs"

const CustomTab = ({ children }) => (
  <Tab
    sx={{
      width: "8px",
      height: "8px",
      borderRadius: "50%",
      cursor: "pointer",
      m: 1,
      p: 1,
      bg: "rgba(242, 250, 251, 0.75)",
      "&:hover": {
        bg: "rgba(242, 250, 251, 1)", //changes the color on hover
      },
      "&:active": {
        bg: "primary", //changes the color on click
      },
      "&:focus": {
        bg: "primary",
        "&:after": {
          bg: "primary",
        },
      },
      "&:after": {
        bg: "primary",
      },
    }}
  >
    <h4>{children}</h4>
  </Tab>
)

CustomTab.tabsRole = "Tab" // Required field to use your custom Tab

export default ({ image, title, description }) => (
  <Box>
    <Tabs>
      <Box
        sx={{
          position: "relative",
          textAlign: "center",
        }}
      >
        <TabPanel>
          <Image />
        </TabPanel>
        <TabPanel>
          <Image2 />
        </TabPanel>
        <TabPanel>
          <Image3 />
        </TabPanel>
        <TabPanel>
          <Image4 />
        </TabPanel>
        <TabPanel>
          <Image5 />
        </TabPanel>
        <Box
          sx={{
            bottom: 0,
            right: 0,
            left: 0,
            top: 0,
            p: 3,
            backgroundImage:
              "linear-gradient(180deg, rgba(48, 53, 54, 0) 60%, rgba(48, 53, 54, 0.64) 120%)",
            position: "absolute",
          }}
        />
        <IconLike />
        <IconExpand />
        <IconShare />
        <Flex
          sx={{
            flexDirection: "column",
            position: "absolute",
            p: 3,
            bottom: 0,
            left: 0,
          }}
        >
          <Box sx={{ mb: 1 }}>
            <TabList
              sx={{
                listStyle: "none",
                p: 0,
                m: 0,
              }}
            >
              <Flex
                sx={{
                  flexDirection: "row",
                }}
              >
                <CustomTab></CustomTab>
                <CustomTab></CustomTab>
                <CustomTab></CustomTab>
                <CustomTab></CustomTab>
                <CustomTab></CustomTab>
              </Flex>
            </TabList>
          </Box>
          <DogName />
        </Flex>
      </Box>
    </Tabs>
  </Box>
)
